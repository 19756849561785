/*
| ===================================================
| This File is for the home page
| ===================================================
*/
<template>
  <v-layout wrap justify-start>
    <v-flex xs12>
      <v-layout wrap justify-center>
        <v-flex xs12 pb-12>
          <v-img src="@/assets/home-green.svg" cover height="60vh">
            <v-layout wrap justify-start fill-height>
              <v-flex xs12 text-center align-self-center>
                <div class="heading">
                  La sicurezza dell’esperienza,<br/> la velocità dell’innovazione
                </div>
              </v-flex>
            </v-layout>
          </v-img>
        </v-flex>
        <v-flex xs12 sm11 md9 lg6 xl4 px-2 py-12>
          <div class="normal-text">
            <span class="bold">CB Digital</span> è la tech company del  <span class="bold">Gruppo Consulbrokers</span> e abbraccia una vision che ha per vocazione al centro le trasformazioni digitali. <br/> <br/>
            L’innovazione infatti impatta non solo sui processi interni ma anche sulle modalità̀ di relazione con gli intermediari e di gestione delle polizze, pertanto investire sull’insurtech
            significa dare continuità̀ e innovazione alla straordinaria esperienza e prestigio che ha segnato la storia della società̀.
            <br/>
            <br/>
            <span class="bold">CB Digital</span> è infatti la società nata per offrire un servizio rapido e di eccellenza nel sia nel rami vari sia nel ramo cauzioni, operando come broker wholesale attraverso una raffinata e evoluta piattaform.
            <br/>
            <br/>
            La Divisione Cauzioni e Fidejussioni della CB Digital opera con le principali Compagnie specializzate e interessate al ramo cauzioni del mercato italiano ed estero e garantisce un servizio di elevato profilo improntato alla ottimizzazione dei costi e dei tempi, dall’analisi del rischio e presentazione al mercato specializzato, all’emissione e consegna del contratto.
          </div>
        </v-flex>
        <v-flex xs12 pt-12>
          <v-img src="@/assets/home-grey.svg" cover min-height="60vh">
            <v-layout wrap justify-center fill-height>
              <v-flex xs12 sm6 md5 lg4 xl3 pa-3 align-self-center>
                <AppCard class="pa-6">
                  <v-layout wrap justify-start>
                    <v-flex xs12 class="card-title">
                      Convenzioni
                    </v-flex>
                    <v-flex xs12 pt-2 class="normal-text">
                      Convenzioni con Enti e/o aziende con le migliori offerte e prodotti presenti sul mercato assicurativo.
                    </v-flex>
                    <v-flex xs12 pt-2>
                      <v-btn color="primary" dark :ripple="false" depressed :to="{name:'conventions'}">
                        <span class="text-capitalize">Vedi tutte</span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </AppCard>
              </v-flex>
              <v-flex xs12 sm6 md5 lg4 xl3 pa-3 align-self-center>
                <AppCard class="pa-6">
                  <v-layout wrap justify-start>
                    <v-flex xs12 class="card-title">
                      Prodotti
                    </v-flex>
                    <v-flex xs12 pt-2 class="normal-text">
                      Il portafoglio prodotti di CB Digital, selezionato tra le migliori garanzie e le compagnie più competitive.
                    </v-flex>
                    <v-flex xs12 pt-2>
                      <v-btn color="primary" dark :ripple="false" depressed :to="{name:'products'}">
                        <span class="text-capitalize">Vedi tutte</span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </AppCard>
              </v-flex>
            </v-layout>
          </v-img>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import AppCard from "@/components/shared/AppCard";

export default {
  name: "Home",
  components: {
    AppCard
  }
}
</script>